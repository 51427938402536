/**
 * Base Application State.
 *
 * @file      state.js
 * @author    Jef Oliver <jef@eljef.me>
 * @copyright 2021, Jef Oliver
 * @licence   See LICENSE file
 */

import { reactive } from 'vue'

const state = reactive( {
    bearer: "",
    error: false,
    loggedIn: false,
    loading: false,
    greeting: "",
    linkData: {},
    menuOpened: false,
    name: "",
    poller: null,
})

export const stateGetters = {
    bearer: () => state.bearer,
    error: () => state.error,
    greeting: () => state.greeting,
    linkData: () => state.linkData,
    loggedIn: () => state.loggedIn,
    loading: () => state.loading,
    menuOpened: () => state.menuOpened,
    name: () => state.name,
    poller: () => state.poller,
}

export const stateSetters = {
    bearerSet: (val) => state.bearer = val,
    errorSet: (val) => state.error = val,
    linkDataSet: (val) => state.linkData = val,
    loggedInSet: (val) => state.loggedIn = val,
    loadingSet: (val) => state.loading = val,
    greetingSet: (val) => state.greeting = val,
    menuOpened: (val) => state.menuOpened = val,
    nameSet: (val) => state.name = val,
    pollerSet: (val) => state.poller = val,
}
