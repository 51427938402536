<template>
  <Error v-if="error"/>
  <Loader v-if="loading && !error"/>
  <Login v-if="!loggedIn && !loading && !error"/>
  <Body v-if="!error" />
</template>

<script>
import { stateGetters, stateSetters } from '@/core/state'
import Body from '@/components/Body.vue'
import Error from '@/components/Error.vue'
import Loader from '@/components/Loader.vue'
import Login from '@/components/Login.vue'

export default {
  name: 'Home',
  components: {
    Body,
    Error,
    Loader,
    Login
  },
  computed: {
    ...stateGetters
  },
  created () {
    stateSetters.menuOpened(false)
  }
}
</script>
