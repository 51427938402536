<template>
  <div class="group_container">
    <div class="group_container__title">
      {{ title }}
    </div>
    <div class="group_container__items">
      <GroupItem v-for="(data, key) of group" :key="key" :name="key" :item="data" class="group_container__item"/>
    </div>
  </div>
</template>
<script>
import GroupItem from '@/components/GroupItem.vue'
export default {
  name: "Group",
  components: {
    GroupItem
  },
  props: {
    group: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="css">
.group_container {
  background-color: var(--color-primary-1);
  border: 1px solid var(--color-border-0);
  border-radius: 10px;
  box-shadow: 3px 4px var(--color-shadow-4);
}
.group_container__items {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 7px;
}
.group_container__title {
  color: var(--color-text);
  font-weight: bold;
  margin-top: 5px;
  text-align: center;
  text-shadow: 1px 2px var(--color-shadow-1);
}
</style>
