<template>
  <div class="ej_error">
    <div class="ej_error__aligner">
      <div class="error">
        <span>An Error Has Occurred</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Error"
}
</script>
<style lang="css">
.ej_error {
  display: flex;
  justify-content: center;
  width: 100%;
}
.ej_error__aligner {
  color: var(--color-error);
  font-size: 120%;
  font-weight: bolder;
  text-align: center;
  text-shadow: 1px 1px var(--color-shadow-1);
  width: 400px;
}
</style>
