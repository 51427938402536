<template>
  <Header/>
  <router-view/>
</template>

<script>
import { stateGetters, stateSetters } from '@/core/state'
import client from '@/core/client'
import Header from '@/components/Header.vue'

export default {
  name: 'App',
  components: {
    Header
  },
  computed: {
    ...stateGetters
  },
  data () {
    return {
      infoPollAndUpdate: null
    }
  },
  methods: {
    getTheme () {
      let currentTheme = client.getTheme()
      if (currentTheme === '') {
        currentTheme = 'blue'
      }
      client.setTheme(currentTheme)
    }
  },
  created () {
    this.getTheme()
    stateSetters.loggedInSet(false)
    stateSetters.loadingSet(true)
    client.storeCookie()
    client.checkOnline().then(() => {
      client.storeUserInfo().then(() => {
        client.storeGroups().then(() => {
          stateSetters.loadingSet(false)
          client.startPolling()
        })
      })
    })
  },
  beforeUnmount() {
    client.stopPolling()
  }
}
</script>
<style lang="css">
:root {
  --color-blue: #2A3246;
  --color-dark: #363636;
  --color-green: #39594D;
  --color-purple: #382946;
}
:root.blue {
  --color-border-0: #000;
  --color-border-1: rgba(0, 0, 0, 0.73);
  --color-border-2: rgba(255, 255, 255, 0.73);
  --color-error: #FF0000;
  --color-primary-0: #2A3246;
  --color-primary-1: #5F6981;
  --color-primary-2: #434D66;
  --color-primary-3: #1B253E;
  --color-primary-4: #0E172E;
  --color-shadow-1: #000;
  --color-shadow-2: rgba(0, 0, 0, 0.10);
  --color-shadow-3: rgba(10, 10, 10, 0.10);
  --color-shadow-4: rgba(10, 10, 10, 0.15);
  --color-text: #F1F1F1;
}
:root.dark {
  --color-border-0: #000;
  --color-border-1: rgba(0, 0, 0, 0.73);
  --color-border-2: rgba(255, 255, 255, 0.73);
  --color-error: #FF0000;
  --color-primary-0: #363636;
  --color-primary-1: #777778;
  --color-primary-2: #575757;
  --color-primary-3: #262626;
  --color-primary-4: #020202;
  --color-shadow-1: #000;
  --color-shadow-2: rgba(0, 0, 0, 0.10);
  --color-shadow-3: rgba(10, 10, 10, 0.10);
  --color-shadow-4: rgba(10, 10, 10, 0.15);
  --color-text: #F1F1F1;
}
:root.green{
  --color-border-0: #000;
  --color-border-1: rgba(0, 0, 0, 0.73);
  --color-border-2: rgba(255, 255, 255, 0.73);
  --color-error: #FF0000;
  --color-primary-0: #39594D;
  --color-primary-1: #728F84;
  --color-primary-2: #517366;
  --color-primary-3: #214135;
  --color-primary-4: #13382A;
  --color-shadow-1: #000;
  --color-shadow-2: rgba(0, 0, 0, 0.10);
  --color-shadow-3: rgba(10, 10, 10, 0.10);
  --color-shadow-4: rgba(10, 10, 10, 0.15);
  --color-text: #F1F1F1;
}
:root.purple {
  --color-border-0: #000;
  --color-border-1: rgba(0, 0, 0, 0.73);
  --color-border-2: rgba(255, 255, 255, 0.73);
  --color-error: #FF0000;
  --color-primary-0: #382946;
  --color-primary-1: #705E82;
  --color-primary-2: #544267;
  --color-primary-3: #2C1A3E;
  --color-primary-4: #1D0D2E;
  --color-shadow-1: #000;
  --color-shadow-2: rgba(0, 0, 0, 0.10);
  --color-shadow-3: rgba(10, 10, 10, 0.10);
  --color-shadow-4: rgba(10, 10, 10, 0.15);
  --color-text: #F1F1F1;
}
html {
  background: linear-gradient(to bottom, var(--color-primary-0) 0%, var(--color-primary-0) 100%);
  height: 100%;
  overflow-x: hidden;
  width: 100%;
}
</style>
