<template>
  <div class="body__groups_holder">
    <Group v-for="(group, key) of linkData" :key="key" :group="group" :title="key" class="body__groups_holder__item"/>
  </div>
</template>
<script>
import { stateGetters } from '@/core/state'
import Group from '@/components/Group.vue'

export default {
  name: "Body",
  components: {
    Group
  },
  computed: {
    ...stateGetters,
  }
}
</script>
<style lang="css">
.body__groups_holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.body__groups_holder__item {
  flex: 0 0 calc(50% - 40px);
  margin: 20px;
  max-width: 482px;
  min-width: 482px;
}
</style>
