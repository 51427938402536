<template>
  <div class="ej_header">
    <div class="ej_header__logo">
      <div class="ej_header__logo-holder">
        <router-link to="/">
          <img alt="ElJef Logo" src="https://home.eljef.me/logo/logo.png" height="64">
        </router-link>
      </div>
    </div>
    <div>
      <div id="ej_header__menu-open" ref="ej_header__menu-open" @click="showMenu()" v-bind:class="{hide: menuOpened}">
        <div class="ej_header__menu-open-main">
          <div v-if="loggedIn" class="ej_header__menu-name_holder">
            <span>{{greeting+" "+name}}</span>
          </div>
          <div>
            <Icon alt="Open Menu" :icon="icons.barsIcon" height="32" color="#f1f1f1"/>
          </div>
        </div>
      </div>
      <div class="ej_header__menu" id="ej_header__menu-close" ref="ej_header__menu-close" v-bind:class="{hide: !menuOpened}">
        <div class="ej_header__menu__holder">
          <div class="ej_header__menu__bars" @click="hideMenu()">
              <Icon alt="Close Menu" :icon="icons.barsIcon" height="32" color="#f1f1f1"/>
          </div>
          <div class="ej_header__menu__main">
            <ul>
              <li v-if="!loggedIn">
                <a v-bind:href="uris.login()">Login</a>
              </li>
              <li v-if="loggedIn" @click="hideMenu()">
                <a v-bind:href="uris.user_profile()" target="_blank">Profile</a>
              </li>
              <li v-if="loggedIn">
                <a v-bind:href="uris.logout()">Logout</a>
              </li>
              <li>
                <router-link to="/">Home</router-link>
              </li>
              <li>
                <router-link to="/policies">Policies</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="ej_header__menu__color_selector">
          <div class="ej_header__menu__color_selector_color background_blue" @click="setTheme('blue')"></div>
          <div class="ej_header__menu__color_selector_color background_dark" @click="setTheme('dark')"></div>
          <div class="ej_header__menu__color_selector_color background_green" @click="setTheme('green')"></div>
          <div class="ej_header__menu__color_selector_color background_purple" @click="setTheme('purple')"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Icon } from '@iconify/vue'
  import barsIcon from '@iconify-icons/la/bars'
  import client from '@/core/client'
  import { stateGetters, stateSetters } from '@/core/state'
  import { uris } from '@/core/uris'

  export default {
    name: "Header",
    components: {
      Icon
    },
    computed: {
      ...stateGetters,
    },
    data () {
      return {
        uris: uris,
        icons: {
          barsIcon,
        }
      }
    },
    methods: {
      hideMenu() {
        stateSetters.menuOpened(false)
      },
      setTheme(themeName) {
        client.setTheme(themeName)
      },
      showMenu() {
        stateSetters.menuOpened(true)
      }
    },
    mounted() {
      document.addEventListener('mouseup', function(e) {
        if (!document.getElementById('ej_header__menu-close').contains(e.target)) {
          stateSetters.menuOpened(false)
        }
      })
    }
  }
</script>
<style lang="css">
  .background_blue {
    background-color: var(--color-blue);
  }
  .background_dark {
    background-color: var(--color-dark);
  }
  .background_green {
    background-color: var(--color-green);
  }
  .background_purple {
    background-color: var(--color-purple);
  }
  .ej_header {
    align-content: space-between;
    display: flex;
    width: 100%;
  }
  .ej_header__logo {
    flex: auto;
  }
  .ej_header__logo-holder {
    width: 64px;
  }
  .ej_header__menu {
    background: linear-gradient(to bottom, var(--color-primary-3) 0%, var(--color-primary-3) 100%);
    border-bottom: 2px solid var(--color-border-1);
    border-bottom-left-radius: 15px;
    border-left: 2px solid var(--color-border-1);
    box-shadow: 0 4px var(--color-shadow-2);
    color: var(--color-text);
    position: absolute;
    right: 0;
    text-shadow: 1px 2px var(--color-shadow-1);
    top: 0;
  }
  .ej_header__menu__bars {
    align-items: center;
    flex: auto;
    justify-content: center;
    padding-left: 5px;
    padding-top: 10px;
  }
  .ej_header__menu__holder {
    display: flex;
  }
  .ej_header__menu__main {
    padding-left: 5px;
    padding-right: 50px;
  }
  .ej_header__menu__color_selector {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 10px;
    width: 100%;
  }
  .ej_header__menu__color_selector_color {
    border: 2px solid var(--color-border-1);
    margin: 5px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    width: 20px;
    min-width: 20px;
    max-width: 20px;
  }
  .ej_header__menu__color_selector_color:hover {
    border: 2px solid var(--color-border-2);
  }
  #ej_header__menu-close {
    opacity: 1;
    transition: opacity 0.5s, right 0.5s, top 0.5s;
  }
  #ej_header__menu-close.hide {
    opacity: 0;
    right: -350px;
    top: -150px;
  }
  #ej_header__menu-open {
    opacity: 1;
    transition: opacity 1s;
  }
  #ej_header__menu-open.hide {
    opacity: 0;
  }
  .ej_header__menu-open-main {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .ej_header__menu-name_holder {
    color: var(--color-text);
    padding-right: 20px;
    text-shadow: 1px 2px var(--color-shadow-1);
  }
  .ej_header__menu__main ul {
    display: block;
    list-style-type: none;
    overflow: hidden;
  }
  .ej_header__menu__main li {
    border-radius: 10px;
    margin: 7px;
  }
  .ej_header__menu__main li a {
    color: var(--color-text);
    display: block;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    text-shadow: 1px 2px var(--color-shadow-1);
  }
  .ej_header__menu__main li:hover {
    background: var(--color-primary-2);
  }
</style>
