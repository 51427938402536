import { createWebHistory, createRouter } from "vue-router";
import Home from "@/vues/Home.vue";
import Policies from "@/vues/Policies.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/policies",
        name: "Policies",
        component: Policies,
    },
];

const router =createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
