/**
 * URIs to use for communications.
 *
 * @file      uris.js
 * @author    Jef Oliver <jef@eljef.me>
 * @copyright 2021, Jef Oliver
 * @licence   See LICENSE file
 */

const URI_BASE = 'https://home.eljef.me'
const URI_API = URI_BASE + '/api'
const URI_LOGIN = URI_BASE + '/home/auth/login'
const URI_LOGOUT = URI_BASE + '/home/auth/logout'
const URI_GROUP_DATA = URI_BASE + '/api/v1/groups'
const URI_POLICIES = URI_BASE + '/policies'
const URI_USER_DATA = URI_BASE + '/api/v1/user'
const URI_USER_PROFILE = URI_BASE + '/auth/realms/ElJef_Home/account/'

export const uris = {
    api: () => URI_API,
    base: () => URI_BASE,
    login: () => URI_LOGIN,
    logout: () => URI_LOGOUT,
    group_data: () => URI_GROUP_DATA,
    policies: () => URI_POLICIES,
    user_data: () => URI_USER_DATA,
    user_profile: () => URI_USER_PROFILE
}
