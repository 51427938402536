<template>
    <div class="policies__holder">
      <div class="policies__holder__header">
        ElJef @ Home Privacy Policy
      </div>
      <div class="policies__holder__text_holder">
        <div class="policies__holder__text">
          <ul>
            <li>ElJef@Home stores your email address and name.
              <ul>
                <li>Used for logging into various ElJef@Home services.</li>
              </ul>
            </li>
            <li>This information is deleted upon deletion of your account.</li>
            <li>Stored information is not:
              <ul>
                <li>Shared with 3rd parties</li>
                <li>Sold to 3rd parties</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="policies__holder__header">
        ElJef @ Home Terms of Service
      </div>
      <div class="policies__holder__text_holder">
        <div class="policies__holder__text">
          <ul>
            <li>Accounts are private.
              <ul>
                <li>Public registration does not exist.</li>
              </ul>
            </li>
            <li>Accounts can be terminated at any time, without notice or cause.</li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
import { stateSetters } from '@/core/state'

export default {
  name: 'Policies',
  created () {
    stateSetters.menuOpened(false)
  }
}
</script>
<style lang="css">
.policies__holder {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
.policies__holder__header {
  color: var(--color-text);
  font-size: 120%;
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 1px var(--color-shadow-1);
}
.policies__holder__text {
  color: var(--color-text);
  text-align: left;
  padding-bottom: 25px;
  padding-top: 25px;
}
.policies__holder__text_holder {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
