<template>
  <div class="login__holder">
    <div class="login__holder__text">
      Welcome to ElJef @ Home
      <br/>Please Log In
    </div>
  </div>
</template>
<script>
export default {
  name: "Login"
}
</script>
<style lang="css">
.login__holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.login__holder__text {
  color: var(--color-text);
  font-size: 120%;
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 1px var(--color-shadow-1);
}
</style>
