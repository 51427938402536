<template>
  <div class="gi_holder">
    <div class="gi_holder__item">
      <a :href="item.uri" target="_blank">
      <div>
        <div class="gi_holder__item_icon">
          <img :src="item.logo" :alt="name" width="64">
        </div>
        <div :class="(longName(name))?'gi_holder__item_text scroll_text':'gi_holder__item_text'">
          {{ name }}
        </div>
      </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "GroupItem",
  props: {
    item: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    longName(name) {
      return (name.length > 8)
    }
  }
}
</script>
<style lang="css">
.gi_holder {
  align-content: center;
  display: flex;
  justify-content: center;
  width: 96px;
}
.gi_holder__item {
  align-content: center;
  display: flex;
  background-color: var(--color-primary-3);
  border: 1px solid var(--color-border-0);
  border-radius: 10px;
  box-shadow: 3px 4px var(--color-shadow-3);
  justify-content: center;
  margin-top: 10px;
  width: 76px;
}
.gi_holder__item:hover {
  background-color: var(--color-primary-4);
  border: 1px solid var(--color-border-0);
}
.gi_holder__item:hover .scroll_text {
  animation: marquee 3s linear infinite;
}
.gi_holder__item a {
  text-decoration: none;
}
.gi_holder__item_icon {
  margin-top: 7px;
}
.gi_holder__item_text {
  color: var(--color-text);
  font-size: 75%;
  margin-bottom: 5px;
  text-align: center;
  text-shadow: 1px 1px var(--color-shadow-1);
  overflow: hidden;
  max-width: 64px;
}
@keyframes marquee {
  0% { text-indent: 0; }
  10% { text-indent: -15%; }
  20% { text-indent: -30%; }
  30% { text-indent: -45%; }
  40% { text-indent: -60%; }
  50% { text-indent: -75%; }
  60% { text-indent: -60%; }
  70% { text-indent: -45%; }
  80% { text-indent: -30%; }
  90% { text-indent: -15%; }
  100% { text-indent: 0; }
}
</style>
