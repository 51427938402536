/**
 * API Backend Client.
 *
 * @file      client.js
 * @author    Jef Oliver <jef@eljef.me>
 * @copyright 2021, Jef Oliver
 * @licence   See LICENSE file
 */

import {stateGetters, stateSetters} from '@/core/state'
import {uris} from '@/core/uris'
import Axios from 'axios';

const cookieHome = 'ej_home'
const cookieTheme = 'ej_theme'

function stopPoller() {
    let poller = stateGetters.poller()
    if (poller !== null) {
        stateSetters.pollerSet(null)
        clearInterval(poller)
    }
}

function handleErr(error) {
    if ((error.response)&&(error.response.status === 401)) {
        stateSetters.bearerSet('')
        stateSetters.errorSet(false)
        handleLoggedOut()
        return
    }
    stateSetters.errorSet(true)
    console.log(error)
}

function handleLoggedOut() {
    stopPoller()
    stateSetters.loggedInSet(false)
    stateSetters.linkDataSet({})
    stateSetters.nameSet("")
    writeCookie(cookieHome, '', 'Thu, 01 Jan 1970 00:00:00 UTC')
}

function readCookie(cookieName) {
    let cookieArray = document.cookie.split(";")
    for (let i = 0; i < cookieArray.length; i++) {
        let data = cookieArray[i].split("=", 2)
        if (data[0].trim() === cookieName) {
            return data[1].trim()
        }
    }

    return ''
}

function writeCookie(cookieName, cookieValue, expires) {
    let cookieData = cookieName + "=" + cookieValue + ";Expires=" + expires + ";SameSite=strict;"
    if (location.protocol === "https:") {
        cookieData += "secure"
    }
    document.cookie = cookieData
}

export default {
    async checkOnline() {
        let bearer = stateGetters.bearer()
        if (bearer === '') {
            handleLoggedOut()
            return
        }

        try {
            const response = await Axios({
                method: 'get',
                url: uris.api(),
                headers: {
                    'Authorization': 'Bearer ' + bearer,
                    'Content-Type': 'application/json'
                }
            })
            if ((response.data !== null)&&
                (typeof response.data === 'object')&&
                ('version' in response.data)&&
                (response.data.version === 1)) {
                stateSetters.loggedInSet(true)
            }
        }
        catch (error) {
            handleErr(error)
        }
    },
    getTheme() {
        return readCookie(cookieTheme)
    },
    setTheme(themeName) {
        document.documentElement.className = themeName;
        writeCookie(cookieTheme, themeName, new Date(new Date().setFullYear(new Date().getFullYear() + 1)))
    },
    async startPolling() {
        stopPoller()
        stateSetters.pollerSet(setInterval(() => {
            this.storeGroups()
            this.storeUserInfo()
            this.updateCookie()
        }, 60000))
    },
    stopPolling() {
        stopPoller()
    },
    storeCookie() {
        stateSetters.bearerSet(readCookie(cookieHome))
    },
    async storeGroups() {
        let bearer = stateGetters.bearer()
        if (bearer === '') {
            return
        }

        try {
            const response = await Axios({
                method: 'get',
                url: uris.group_data(),
                headers: {
                    'Authorization': 'Bearer ' + bearer,
                    'Content-Type': 'application/json'
                }
            })
            if ((response.data !== null) &&
                (typeof response.data === 'object') &&
                ('groups' in response.data)) {
                stateSetters.linkDataSet(response.data.groups)
            }
        }
        catch (error) {
            handleErr(error)
        }
    },
    async storeUserInfo() {
        let bearer = stateGetters.bearer()
        if (bearer === '') {
            return
        }

        try {
            const response = await Axios({
                method: 'get',
                url: uris.user_data(),
                headers: {
                    'Authorization': 'Bearer ' + bearer,
                    'Content-Type': 'application/json'
                }
            })
            if ((response.data !== null)&&(typeof response.data === 'object')) {
                let usersName = 'Anon'
                if ('given_name' in response.data) {
                    usersName = response.data['given_name']
                } else if ('name' in response.data) {
                    usersName = response.data['name']
                }
                stateSetters.nameSet(usersName)

                let greeting = "Hello!"
                if ('greeting' in response.data) {
                    greeting = response.data['greeting']
                }
                stateSetters.greetingSet(greeting)
            }
        }
        catch (error) {
            handleErr(error)
        }
    },
    async updateCookie() {
        let date = new Date();
        date.setTime(date.getTime() + (15 * 60 * 1000))
        writeCookie(cookieHome, stateGetters.bearer(), date.toUTCString())
    }
}
